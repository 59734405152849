/* global maplibregl */

Apt.fn.extend('maps', {}, {
	/**
	 * Initialize pitching
	 */
	initPitch: function() {
		var scope = this;

		if (! scope.active) {
			return;
		}

		scope.pitched = false;

		$.events.on('$mapTerrain', 'click', function() {
			scope.pitched = ! scope.pitched;

			if (scope.pitched) {
				scope.setState();

				LS.analytics.interact('map', 'terrain');
			}

			scope.setPitch(scope.pitched);
		}, {
			delegate: scope.$overlay,
			namespace: scope.uid
		});

		['pitchend', 'rotateend', 'zoomend'].forEach(function(event) {
			scope.$public.bind('map', event, function() {
				if (scope.pitchTimer) {
					clearTimeout(scope.pitchTimer);
				}

				scope.pitchTimer = setTimeout(function() {
					clearTimeout(scope.pitchTimer);

					scope.pitchTimer = null;

					scope.togglePitch();
				}, 100);
			});
		});

		scope.togglePitch();
	},

	/**
	 * Toggle pitch control
	 */
	togglePitch: function() {
		var scope = this;

		if (! scope.active) {
			return;
		}

		var enable = scope.map.getZoom() >= 12,
			positioned = scope.map.getBearing() ||
				scope.map.getPitch();

		scope.$parent.toggleClass('-is-pitchable', enable);

		if (positioned && ! enable) {
			scope.setPitch(false);

			return;
		}

		if (positioned) {
			scope.addPitch();
		} else {
			scope.removePitch();
		}
	},

	/**
	 * Reset pitch setting
	 *
	 * @param {Boolean} [bearing=false]
	 * @param {Boolean} [animate=true]
	 */
	resetPitch: function(bearing, animate) {
		var scope = this,
			options = {
				pitch: 0
			};

		if (bearing) {
			options.bearing = 0;

			scope.map.setTerrain(null);
		}

		scope.map[
			animate === false ?
				'jumpTo' : 'easeTo'
		](options);

		scope.model.$set('terrain', false);
	},

	/**
	 * Add pitch control
	 */
	addPitch: function() {
		var scope = this;

		if (scope.pitchControl) {
			return;
		}

		scope.pitchControl = new maplibregl.NavigationControl({
			showZoom: false
		});

		scope.map.addControl(scope.pitchControl, 'bottom-right');
	},

	/**
	 * Set pitch state
	 *
	 * @param [state=false]
	 * @param {Boolean} [bearing=false]
	 */
	setPitch: function(state, bearing) {
		var scope = this,
			map = scope.map;

		scope.pitched = state;

		if (scope.model) {
			scope.model.$merge({
				show: false,
				terrain: state
			});
		}

		scope.removeOptions();

		if (! state) {
			scope.resetPitch(bearing);

			return;
		}

		if (! map.getSource('depth')) {
			scope.addSource('depth', {
				type: 'raster-dem',
				encoding: 'terrarium',
				tiles: [
					'https://cdn.landsearch.com/terrarium/{z}/{x}/{y}.png'
				],
				tileSize: 256,
				maxzoom: 15
			});
		}

		map.setTerrain({
			source: 'depth'
		});

		scope.$public.ready(function() {
			map.easeTo({
				pitch: 65
			});

			scope.setState(true);
		});
	},

	/**
	 * Disable pitch control
	 */
	removePitch: function() {
		var scope = this;

		if (scope.pitchControl) {
			scope.map.removeControl(scope.pitchControl);

			scope.pitchControl = null;
		}
	}
});