Apt.fn.extend('maps', {}, {
	/**
	 * Bind events for polygons
	 *
	 * @param {String} layer
	 */
	bindPoly: function(layer) {
		var scope = this,
			pub = scope.$public;

		pub.bind(layer, 'mousemove', function(e) {
			if ($.supported('touch')) {
				return;
			}

			if (
				! scope.hoverMarker &&
				! scope.tooltip
			) {
				scope.hoverPoly(layer, e.point);
			}
		});

		pub.bind(layer, 'mouseleave', function() {
			if ($.supported('touch')) {
				return;
			}

			if (! scope.hoverMarker) {
				scope.deactivate();
			}
		});

		pub.bind(layer, 'click', function(e) {
			if (scope.draw || scope.model.$get('showOptions')) {
				return;
			}

			scope.tooltip ?
				scope.hoverPoly(layer, e.point) :
				scope.clickPoly(layer, e);
		});
	},

	/**
	 * Zoom in to center of clicked polygon
	 *
	 * @param {String} layer
	 * @param {Object} e
	 */
	clickPoly: function(layer, e) {
		var scope = this,
			zoom = scope.map.getZoom();

		if (
			scope.inspect ||
			zoom === 19.4
		) {
			return;
		}

		if (
			zoom > scope.break &&
			$.supported('touch') &&
			$.screen.size() < 4
		) {
			return;
		}

		if (
			scope.hoverMarker ||
			scope.$public.tooltip ||
			scope.model.$get('showOptions')
		) {
			return;
		}

		var features = scope.map.queryRenderedFeatures(e.point, {
			layers: [layer],
			validate: false
		});

		if (
			features.length !== 1 ||
			features[0].layer.id !== layer
		) {
			return;
		}

		scope.$public.center(e.lngLat, {
			animate: true,
			zoom: Math.max(zoom + 2, zoom >= 5 ? 9 : 5)
		});
	},

	/**
	 * Determine applicable hovered polygon and activate it
	 *
	 * @param {String} layer
	 * @param {Object} point
	 */
	hoverPoly: function(layer, point) {
		var scope = this,
			zoom = scope.map.getZoom();

		// Ensure layer is visible within zoom ranges
		if ((
			zoom < scope.break && (
				(layer === 'territories' && zoom < 5) ||
				(layer === 'subterritories' && zoom >= 5)
			)
		)) {
			var features = scope.map.queryRenderedFeatures(point, {
				layers: [layer],
				validate: false
			});

			if (features.length) {
				scope.activate(layer, features[0]);
			} else {
				scope.deactivate();
			}
		}
	},

	/**
	 * Show the minimum zoom message
	 *
	 * @param {Object} layer
	 * @param {Object} feature
	 */
	activate: function(layer, feature) {
		var scope = this;

		if (scope.map.getZoom() >= scope.break) {
			return;
		}

		if (
			scope.hoverFeature &&
			scope.hoverFeature.id === feature.id
		) {
			return;
		}

		if (scope.hoverFeature) {
			scope.map.setFeatureState(scope.hoverFeature, {
				hover: false
			});
		}

		scope.map.setFeatureState(feature, {
			hover: true
		});

		scope.hoverFeature = feature;

		scope.model.$set('status', 'Counting listings');

		scope.getGroup(layer, feature.id, function(count) {
			scope.model.$merge({
				count: count,
				location: feature.properties.name,
				status: false
			});
		});
	},

	/**
	 * Hide the minimum zoom message
	 *
	 * @param {Number} [zoom]
	 */
	deactivate: function(zoom) {
		var scope = this;
		zoom = zoom || scope.map.getZoom();

		if (! scope.conf.showPoints && zoom >= scope.break) {
			return;
		}

		scope.model.$merge({
			location: false,
			status: false
		});

		if (scope.hoverFeature) {
			scope.map.setFeatureState(scope.hoverFeature, {
				hover: false
			});
		} else {
			scope.toggleZoom();
		}

		scope.hoverFeature = null;
	}
});